import React from 'react'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';

import Container from '@material-ui/core/Container'


const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => (
  <Layout>
    <Helmet title={`Tags | ${title}`} />
    <section className="Section">
    <Container>
      <div className="PageTitle-section">
        <h3 className="TextCenter">
          All Tags<span>タグ一覧</span>
        </h3>
      </div>

      <Grid container spacing={3}>
          {group.map(tag => (
            <Grid item xs={4} key={tag.fieldValue}>
              <Card>
                <CardActionArea href={`/tags/${kebabCase(tag.fieldValue)}/`} >
                  <CardContent>
                    <Typography variant="h6" component="h2">
                      <Box fontWeight="fontWeightBold">{tag.fieldValue} ({tag.totalCount})</Box>
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
      </Grid>
      </Container>
      </section>
  </Layout>
)

export default TagsPage

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
